.component {
  background: #fbfdfd;
  min-height: 316px;
  box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
  border-radius: 16px;
}

.button {
  background: linear-gradient(
    317.83deg,
    #01e285 -13.04%,
    #09cead 127.04%
  ) !important;
  border-radius: 5px;
}
:global {
  .wallet-table {
    background: #fbfdfd;
    max-height: 12rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(128, 128, 128, 0.26);
    }
    .ant-table {
      background: transparent;
      & .ant-table-container,
      & table {
        border: none !important;
      }
    }
    .ant-table-thead > tr > th {
      background: transparent;
      border-bottom: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      padding: 0;
      text-align: center;
      &:before {
        content: none !important;
      }
    }
    .ant-table-tbody > tr > td {
      border-bottom: none;
      background: transparent;
      padding: 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #231d56;
    }
    .ant-table.ant-table-bordered table > tbody > tr > td,
    .ant-table.ant-table-bordered table > thead > tr > th {
      border-left: 1px dashed #c6c4d4;
      border-right: none !important;
    }
    .ant-table.ant-table-bordered table > tbody > tr > td:first-child,
    .ant-table.ant-table-bordered table > thead > tr > th:first-child {
      border: none;
    }
    .ant-table table > thead > tr > th {
      color: #16c37b;
      &:last-child {
        font-weight: 900;
      }
    }
  }
}
