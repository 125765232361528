.cardContainer {
  background: #fbfdfd;
  box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
  border-radius: 20px;
  min-height: 425px;
}
.moreComponent {
  background: #e0f5ef;
  border-radius: 5px;
}
