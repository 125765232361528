.btnCustom {
  &.green {
    background: #1eae73;
  }
  &.light-green {
    background: linear-gradient(317.83deg, #01e285 -13.04%, #09cead 127.04%);
  }
  &.tea-green {
    background: #e0f5ef;
  }
  &.grey {
    background: #c6c4d4;
  }
  &.orange {
    background: #ff7f5c;
  }
  &.red {
    background: #e76868;
  }
  &.disabled {
    pointer-events: none;
    background: #c6c4d4;
  }
  &.default {
    font-weight: 700;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.lg {
      width: 205px;
      height: 36px;
    }
    &.md {
      width: 115px;
      height: 30px;
    }
    &.sm {
      width: 110px;
      height: 36px;
    }
    &.xs {
      width: 70px;
      height: 36px;
    }
  }

  &.circle {
    box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.lg {
      width: 50px;
      height: 50px;
    }
    &.md {
      width: 40px;
      height: 40px;
    }
    &.sm {
      width: 30px;
      height: 30px;
    }
    &.xs {
      width: 20px;
      height: 20px;
    }

    &.white {
      background: #fbfdfd;
    }
    &.green {
      background: #1eae73;
    }
    &.light-green {
      background: linear-gradient(317.83deg, #01e285 -13.04%, #09cead 127.04%);
    }
    &.grey {
      background: #c6c4d4;
    }
    &.orange {
      background: #ff7f5c;
    }
    &.red {
      background: #e76868;
    }
  }

  &.loading {
    .spiner {
      animation: spin-animation 1s infinite;
      animation-timing-function: linear;
    }
    @keyframes spin-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
}

// .btn-custom {
//   cursor: pointer;
//   display: inline-block;
//   &.disabled {
//     &:not(.loading) {
//       background: #d4d4d4 !important;
//     }
//     cursor: not-allowed !important;
//     &:hover {
//       box-shadow: none !important;
//     }
//     &:active {
//       box-shadow: none !important;
//     }
//   }
//   // &.sm {
//   //   // color: red;
//   // }
//   // &.md {
//   // }
//   // &.lg {
//   // }
//   // &.xl {
//   // }
//   &.only-text {
//     // color: hsl(210, 4%, 10%);
//     // &.sm {
//     //   font: normal normal 600 14px/22px Open Sans;
//     // }
//     // &.md {
//     //   font: normal normal bold 16px/22px Open Sans;
//     // }
//     // &.lg {
//     // }
//     // &.xl {
//     // }
//     // &:hover {
//     //   font: normal normal bold 15px Open Sans;
//     // }
//     // &:active {
//     //   color: #7fd675;
//     // }
//     .onlyTextSpin {
//       right: -23px;
//       font-size: initial;
//       top: 1px;
//     }
//   }
//   &.default {
//     border-radius: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 17px 28px;
//     color: #ffffff;
//     background: transparent linear-gradient(180deg, #153d23 0%, #0e2526 100%) 0%
//       0% no-repeat;
//     &:hover {
//       box-shadow: 2px 3px 15px #2e65277c;
//     }
//     &:active {
//       background: transparent linear-gradient(180deg, #1f5b34 0%, #193739 100%)
//         0% 0% no-repeat;
//       box-shadow: 2px 3px 15px #2e65277c;
//     }
//   }
//   &.secondary {
//     font: normal normal 600 14px/12px Open Sans;
//     color: #18191a;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 54px;
//     border: 1px solid #18191a;
//     border-radius: 70px;
//     &:hover {
//       box-shadow: 2px 3px 15px #18191a33;
//     }
//     &:active {
//       color: #515864;
//       box-shadow: 2px 3px 15px #18191a33;
//     }
//   }
//   &.white-bordered {
//     border-radius: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     color: #18191a;
//     border: 1px solid #f5f5f6;
//     &:hover {
//       background-color: #f8f8f9;
//     }
//     &:active {
//       background: #f5f5f6;
//       color: #515864;
//     }
//   }
//   &.circle {
//     border-radius: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     &.sm {
//       font: normal normal bold 14px/22px Open Sans;
//       width: 44px;
//       height: 44px;
//       background-color: #f5f5f6;
//       > img {
//         width: unset;
//         height: unset;
//       }
//     }
//     &.md {
//       box-shadow: 2px 3px 15px #e3e3e3ba;
//       font: normal normal bold 16px/12px Open Sans;
//       width: 60px;
//       height: 60px;
//       > img {
//         width: 27px;
//         height: 27px;
//       }
//     }
//     // &.lg {
//     // }
//     // &.xl {
//     // }
//   }
//   &.light-green {
//     background: transparent linear-gradient(180deg, #49c53b 0%, #38a12c 100%);
//     border-radius: 30px;
//     &:hover {
//       box-shadow: 2px 3px 15px #49c53b66;
//     }
//     &:active {
//       background: transparent linear-gradient(180deg, #6ed062 0%, #5fb356 100%)
//         0% 0% no-repeat;
//       box-shadow: 2px 3px 15px #49c53b66;
//     }
//   }
//   &.white-shadowed {
//     height: 54px;
//     border-radius: 30px;
//     display: flex;
//     align-items: center;
//     box-shadow: 2px 3px 15px #e3e3e3ba;
//     background: white;
//     font: normal normal 600 14px/18px Open Sans;
//     &:hover {
//       background: #f8f8f9 0% 0% no-repeat padding-box;
//       box-shadow: 2px 3px 15px #18191a33;
//     }
//     &:active {
//       background: #f5f5f6 0% 0% no-repeat padding-box;
//       box-shadow: 2px 3px 15px #18191a33;
//     }
//   }
//   &.white {
//     width: 376px;
//     height: 54px;
//     border-radius: 30px;
//     display: flex;
//     align-items: center;
//     font: normal normal 600 14px/12px Open Sans;
//     border: 1px solid #0e2525;
//     background: white;
//     &.sm {
//       width: unset;
//       height: unset;
//       font: normal normal 600 14px/22px Open Sans;
//       padding: 10px 25px;
//       width: 216px;
//       height: 54px;
//     }
//     &.md {
//       font: normal normal 600 16px/22px Open Sans;
//     }
//     // &.lg {
//     // }
//     // &.xl {
//     // }
//     > img {
//       margin-left: 15px;
//     }
//     > .empty-div-for-icon {
//       width: 27px;
//       height: 27px;
//       margin-right: 15px;
//     }
//     &:hover {
//       box-shadow: 2px 3px 15px #18191a33;
//       border: 1px solid #18191a;
//     }
//     &:active {
//       color: #515864;
//       box-shadow: 2px 3px 15px #18191a33;
//       border: 1px solid #515864;
//     }
//     &.disabled {
//       &:not(.loading) {
//         border: 1px solid #18191a;
//         color: #18191a;
//       }
//       cursor: not-allowed !important;
//       &:hover {
//         box-shadow: none !important;
//       }
//       &:active {
//         box-shadow: none !important;
//       }
//     }
//   }
//   .spin {
//     animation: spin-animation 1s infinite;
//     animation-timing-function: linear;
//   }
//   @keyframes spin-animation {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(359deg);
//     }
//   }
// }
