.container {
  width: 296px;
  display: flex;
  flex-direction: column;
  & > label {
    font-weight: 700;
    font-size: 14px;
  }
  & > input {
    height: 36px;
    padding: 0 12px;
    border: 1px solid #e0f5ef;
    background: #e0f5ef;
    border-radius: 4px;
    &::placeholder {
      color: black;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
