.approveBeneficiaryCont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media only screen and (max-width: '1300px') {
    grid-template-columns: repeat(1, 1fr);
  }
  .approveBeneficiaryItem {
    background: #fbfdfd;
    box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
    border-radius: 20px;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 2rem;
    max-width: 30rem;
    @media only screen and (max-width: '1300px') {
      width: 100%;
      margin: auto;
      margin-top: 2rem;
    }
  }
}
.inputsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  padding-top: 2rem;
}
