.contentContainer {
  margin: 20px 56px 0 18px;
  padding: 42px 60px;
  background: #fffffe;
  box-shadow: 0px 6px 12px rgba(142, 150, 240, 0.06);
  border-radius: 16px;
  position: relative;
  .tabContainer {
    overflow-x: scroll;
    white-space: nowrap;
    .tabListContainer {
      position: relative;
      padding-right: 30px;
    }
    .tabPanel {
      position: absolute;
      left: 50px;
      width: 100%;
      .dataItemCont {
        width: calc(100% - 100px);
      }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
:global {
  .react-tabs__tab-list {
    border-bottom: none !important;
    font-weight: 900;
    .lighter {
      font-weight: 500;
    }
    .react-tabs__tab {
      border-right: 1px solid #e5e7eb;
      margin: 6px 0;
      padding: 0 18px !important;
    }
    .react-tabs__tab:last-child {
      border-right: none !important;
    }
    .react-tabs__tab:first-child {
      padding-left: 0 !important;
    }
    .react-tabs__tab.react-tabs__tab--selected {
      border: none;
      border-radius: none !important;
      border-radius: 0;
      color: #16c37b;
      border-right: 1px solid #e5e7eb;
      padding: 0 12px;
      div {
        border-bottom: 6px #16c37b solid;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 2px solid transparent;
        border-left: 2px solid transparent;
      }
    }
  }
  .react-tabs__tab:first-child.react-tabs__tab--selected {
    &::after {
      margin-left: 0 !important;
    }
  }
}
