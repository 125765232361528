.tabContainer {
  div {
    position: relative;
    top: 9px;
    padding-bottom: 5px;
    z-index: 1;
  }
  :global {
    .react-tabs__tab-list {
      border-bottom: 1px solid #c6c4d4 !important;
      font-weight: 700;
      color: #c6c4d4;
      padding-bottom: 1px;
    }
    .react-tabs__tab {
      border-right: none !important;
      text-align: left !important;
      width: 95px;
      padding-right: 0px !important;
    }
  }
}
.teamTableCont {
  position: relative !important;
  top: -30px !important;
  :global {
    .ant-table-measure-row {
      display: none;
    }
    .ant-table-thead > tr > th {
      display: none;
      border-bottom: none !important;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #c6c4d4 !important;
      font-weight: 700 !important;
      white-space: nowrap;
    }
    .ant-table.ant-table-bordered table > tbody > tr > td,
    .ant-table.ant-table-bordered table > thead > tr > th {
      border-left: none !important;
    }
    ul {
      margin-top: 50px !important;
      .ant-pagination-next[aria-disabled='false'],
      .ant-pagination-prev[aria-disabled='false'] {
        color: #16c37b !important;
        svg {
          color: #16c37b !important;
        }
      }
      .ant-pagination-item {
        & a {
          color: #c6c4d4 !important;
        }
      }
      .ant-pagination-item-active {
        border: 1px solid transparent;
        & a {
          color: #16c37b !important;
        }
      }
    }
  }
}
