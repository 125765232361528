.cardContainer {
  background: #fbfdfd;
  box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
  border-radius: 20px;
}
.historyContainer {
  background: #fbfdfd;
  box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
  border-radius: 20px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  min-height: 429px;
  position: relative;
  z-index: 100;
  margin-top: -50px;
}
:global {
  .popover_overlay_for_table {
    &.ant-popover {
      background: #f7f7ff;
      padding-top: 0;
      width: 60%;
      height: 70px;
      .ant-popover-content {
        height: 100%;
        .ant-popover-inner {
          box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
          border-radius: 8px;
          height: 100%;
          background: transparent;
          .ant-popover-inner-content {
            height: 100%;
          }
        }
      }
      .ant-popover-arrow {
        display: none;
      }
    }
  }
}
.popoverContent {
  padding: 10px 52px;
}
