.container {
  width: 296px;
  & > label {
    font-weight: 700;
    font-size: 14px;
  }
  .datePicker {
    width: 100%;
    height: 36px;
    padding: 0;
    border: 1px solid #c6c4d4;
    border-radius: 4px;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: #c6c4d4;
      font-size: 14px;
    }
    .suffixIcon {
      position: relative;
      right: 6px;
    }
  }
}
:global {
  .ant-picker {
    .ant-picker-input {
      padding-left: 10px;
      > input {
        height: 32px;
        width: 100%;
      }
    }
    .ant-picker-focused {
      box-shadow: none;
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid #16c37b !important;
  }
  .ant-picker-today-btn {
    color: #16c37b !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #16c37b !important;
  }
  .ant-picker-clear {
    right: 10px !important;
  }
  .ant-picker-date-panel {
    width: 296px !important;
    .ant-picker-content {
      width: 271px !important;
    }
  }
}
