:global {
  .table_component {
    background: transparent;
    .ant-table {
      background: transparent;
      & .ant-table-container,
      & table {
        border: none !important;
      }
    }
    .ant-table-thead > tr > th {
      background: transparent;
      border-bottom: none;
      font-weight: 900;
      font-size: 14px;
      line-height: 20px;
      color: #231d56;
      text-align: center;
      &:before {
        content: none !important;
      }
    }
    .ant-table-tbody > tr > td {
      border-bottom: none;
      background: transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 7px 16px;
      color: #231d56;
    }
    .ant-table.ant-table-bordered table > tbody > tr > td,
    .ant-table.ant-table-bordered table > thead > tr > th {
      border-left: 1px solid #c6c4d4;
      border-right: none !important;
    }
    .ant-table.ant-table-bordered table > tbody > tr > td:first-child,
    .ant-table.ant-table-bordered table > thead > tr > th:first-child {
      border: none;
    }
  }
}
