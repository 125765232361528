.homeContainer {
  background: #e5e5e5;
  height: 100%;
}
.headerContainer {
  background-color: #fbfdfd;
  height: 100px;
  box-shadow: 0px 6px 12px rgba(142, 150, 240, 0.06);
  padding: 18px 62px;
  line-height: 18px;
}
.sidebarContainer {
  margin-top: 20px;
  margin-bottom: 36px;
  background: #fffffe;
  box-shadow: 0px 6px 12px rgba(142, 150, 240, 0.06);
  border-radius: 0px 16px 16px 0px;
  & > div {
    padding: 2rem 8px 2rem 0;
    overflow-y: overlay;
    overflow-x: hidden;
    width: calc(100% - 12px);
  }
}
:global {
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #231d5600;
    border-radius: 20px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #f8f8f8;
    border-radius: 20px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b4b5b7;
    border-radius: 20px;
  }
}
.contentContainer {
  margin: 20px 56px 0 18px;
  padding: 42px 60px;
  background: #fffffe;
  box-shadow: 0px 6px 12px rgba(142, 150, 240, 0.06);
  border-radius: 16px 16px 0 0;
}
.contentWithoutSider {
  margin: 20px 62px 0 62px;
}

.sidebarMenuItem {
  padding: 20px 15px 20px 20px !important;
  margin-bottom: 30px;
  height: auto;
  background: #f7f7ff;
  border-radius: 10px;
}
.sidebarMenuSubitem {
  padding: 15px 0 !important;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #c6c4d4;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  margin-bottom: 30px;
}

.activeTik {
  background: linear-gradient(317.83deg, #01e285 -13.04%, #09cead 127.04%);
  border-radius: 0px 10px 10px 0px;
  width: 15px;
  height: 60px;
  top: 5px;
}

.activeMenuItem {
  background: linear-gradient(317.83deg, #01e285 -13.04%, #09cead 127.04%);
  border-radius: 10px;
}

.sidebarActiveSubitem {
  background: #ffffff;
  border-color: #16c37b;
}

.burgerMenu {
  background: #ffffff;
  box-shadow: 0px 7px 11px rgba(35, 109, 79, 0.12);
  border-radius: 10px;
  width: 180px;
  display: flex;
  flex-direction: column;
}
